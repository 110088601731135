import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  .headingTwo {
    padding: 0 16px;
    margin-bottom: 0;
  }

  .Link {
    color: black;
  }

  .author {
    padding: 0;
    margin: 0 0 16px 0;
    text-align: center;
  }


  @media (min-width: 768px) {
    .articleWrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
      margin-bottom: 32px;
    }

    .author {
      font-size: 16px;
    }
  }
`

const Blog = ({ data }) => {
  const { posts } = data.blog

  return (
    <Layout>
      <Seo
        title="Blog Posts"
        description="Tips on home renovation and restoration projects"
        canonical="https://ecotechrestoration.ca/"
      />
      <h1>Blog</h1>

      {posts.map(post => (
        <Wrapper>
          <article className="articleWrapper" key={post.id}>
            <div>
              <GatsbyImage
                image={
                  post.frontmatter.cover_image.childImageSharp.gatsbyImageData
                }
                alt={post.title}
              />
            </div>
            <div>
              <Link className="Link" to={post.fields.slug}>
                <h2 className="headingTwo">{post.frontmatter.title}</h2>
              </Link>
              <p className="author"> by {post.frontmatter.author}</p>
              <p>{post.excerpt}</p>
            </div>
          </article>
        </Wrapper>
      ))}
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(
      filter: { frontmatter: { tag: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      posts: nodes {
        fields {
          slug
        }
        id
        excerpt
        frontmatter {
          date
          title
          author
          cover_image {
            childImageSharp {
              gatsbyImageData(formats: AUTO, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, breakpoints: [360, 768, 1100], quality: 80)
            }
          }
        }
      }
    }
  }
`
